* {
    box-sizing: border-box;
}
ul {
    margin-left: 0;
}

li {
    list-style-position: inside;
}


.pageTitle {
    font-family: AmericanSans;
    font-size: 32px;
    color: #181E25;
    text-align: left;
}
#formBody {
    transform: scale(0.8); 
    transform-origin: top left; 
}
.bodyCopy {
    font-family: AmericanSans;
    font-size: 16px;
    color: #39495A;
    text-align: left;
    line-height: 21px;
}

.AuthByWrapper {
    width: calc(100% + 64px);
}

.AuthBySection {
    padding: 20px 0;
    background: #F5F7F7;
    box-sizing: border-box;
}

.table-container {
    margin-left: 64px; /* Margin to align with body padding */
    width: calc(100% - 64px);
}

.padded-table {
    padding-left: 64px; /* Add left padding to the table */
    background-color: white; /* Just for visual differentiation */
    border-collapse: collapse;
}

    .padded-table th, .padded-table td {
        border: 1px solid #000;
        padding: 8px;
        text-align: left;
    }

.authBox {
    background: #FFFFFF;
    border: 1px solid #637E9C;
    border-radius: 5px;
    height: 80px;
}

.authTable {
    background: #FFFFFF;
    border: 1px solid #637E9C;
    border-radius: 5px;
}

.authByText {
    font-family: AmericanSans;
    font-size: 24px;
    color: #181E25;
    text-align: left;
}

.authByName {
    font-family: AmericanSans;
    font-size: 18px;
    color: #181E25;
    text-align: left;
}

.authText {
    font-family: AmericanSans;
    font-size: 14px;
    color: #39495A;
    text-align: left;
}

.container {
    width: calc(100% + 64px); /* Stretching the container 64px wider */
    background: #F5F7F7;
    box-sizing: border-box;
    position: relative;
    left: -64px; /* Moving the container 64px to the left */
    padding-left: 64px; /* Ensuring content starts at the same position */
    padding-right: 64px; /* Ensuring padding on the right side as well */
    padding-bottom: 32px; /* Padding at the bottom */
    margin-left: 0;
}

.title {
    font-family: AmericanSans;
    color: #181E25;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.content {
    display: flex;
    overflow: hidden;
}

.text-content {
    font-family: AmericanSans;
    flex-grow: 1;
    background: #FFFFFF;
    border: 1px solid #637E9C;
    border-radius: 5px;
    min-height: 80px; /* Ensures the box is at least 80px tall */
    padding-left: 16px;
    padding-top: 8px; /* Add padding at the top for spacing */
    padding-bottom: 8px; /* Add padding at the bottom for spacing */
    overflow: auto; /* Allows scrolling if content exceeds box height */
    box-sizing: border-box; /* Ensure padding is included in height calculation */
}

#authVariables {
    font-weight: bold;
}

.name {
    font-size: 24px;
    font-family: AmericanSans;
    color: #181E25;
    text-align: left;
}

.placeholders {
    font-family: AmericanSans;
    color: #39495A;
    text-align: left;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5%;
}

    .placeholders div {
        margin-bottom: 5px;
    }

.image {
    background: #637E9C;
    border-radius: 4px 0 0 4px;
    display: flex; /* Use Flexbox to align content */
    align-items: center; /* Center the image vertically */
    justify-content: center;
    width: 5%; /* Responsive width */
    max-width: 200px;
}

#PV {
    padding-top: 32px; /* Adding 32px top padding */
    text-align: left; /* Aligning content to the right */
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.app {
    width: 100%;
    display: flex;
    justify-content: center;
}

.authTitle {
    font-family: AmericanSans;
    font-size: 32px;
    color: #181E25;
    text-align: left;
}

.authColumn {
}

.travel-authorization-form {
    font-family: AmericanSans;
    padding-left: 64px;
    width: 75%;
    max-width: 1010px;
}

.divider {
    width: 90%;
    height: 1px;
    background-color: grey;
    margin: 0 auto;
}

#banner, .logOut {
    background-color: #1476b6;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    margin: 0;
}

#myCasesAll {
}

#myCasesHead {
    display: flex;
    justify-content: space-between;
}

#toggleFilterButton {
    height: 50px;
    color: #1476B6;
    width: 130px;
}

#pagination {
    text-align: right;
    margin-top: 1%;
}

#pageBar {
}

#pageButton {
    background: white;
    font-size: 20px;
    padding: 10px;
    margin-right: 2px;
    margin-left: 2px;
    border: 0px;
    width: 40px;
    color: #1476B6;
}

    #pageButton:hover {
        font-weight: bold;
        background-color: lightgray;
    }

    #pageButton:disabled {
        color: gray;
        background-color: white;
        font-weight: normal;
    }

button.active {
    color: white;
    background-color: #1476B6;
}

table, th, td {
}
#inputBox {
    border: 1px solid #333;
}
#caseRow {
    height: 32px;
    border: 1px ridge black;
    color: black;
    font-family: AmericanSans;
}

#subject {
    width: 30%;
}

#caseNumber {
    width: 10%;
}

#lastUpdated {
    width: 15%;
}

#status {
    width: 15%;
}

.alternate-row {
    background-color: #c2e9ff;
}

.center {
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: normal;
}

.authForm {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 32px;
    color: #39495A;
}

#filterBox {
    width: 100%;
    background-color: #EEF1F2;
    display: inline-grid;
    padding-top: 25px;
    padding-bottom: 10px;
}

#filterHead {
    color: #658893;
    font-size: 30px;
    font-family: "72", "72full", Arial, Helvetica, sans-serif;
    padding-left: 20px;
    margin-bottom: 2rem;
    border-bottom: 1px solid #d3d4d4;
}

ul {
    padding-left: 0rem;
}

.A9Foot {
}

.filtersub {
    display: flex;
    color: dimgrey;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.filterbox {
    width: 90%;
    height: 50px;
    padding: 0.25rem;
    border-color: #EEF1F2;
}

.dt {
    width: 200px;
    height: 50px;
    margin-left: 0.25rem;
    padding: 0.25rem;
    border-color: #EEF1F2;
}

#casesBox {
    width: 100%; /* Make table take full width of its container */
    max-width: 1000px;
    border: 1px solid gray;
    word-wrap: break-word;
    font-size: 20px;
    padding-bottom: 10px;
    padding-top: 20px;
}

#casesHead {
    display: flex;
    justify-content: space-evenly;
}

#filterItem {
    text-align: left;
    padding-left: 1rem;
    width: 25%;
    font-size: 18px;
}

.dt {
    width: 45%;
    height: 50px;
    margin-left: 0.25rem;
    padding: 0.25rem;
    border-color: #EEF1F2;
}

#applyFilter {
    display: flex;
    justify-content: flex-end;
    float: right;
    width: 97%;
}

.btn {
    background-color: #1476B6;
    color: white;
    border-radius: 0.25rem;
    margin-right: 10px;
}

.tableheader {
    color: #fff;
    background-color: #1476B6;
    padding-left: 12px;
    font-family: AmericanSans;
}

.columnname {
    color: #fff;
    font-weight: 500;
    padding: 1rem;
}

.save-btn {
    width: 250px;
    color: white;
    background-color: #1476B6;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #1476B6;
    margin-top: 10px;
}

    .save-btn:hover {
        background-color: #346187;
        border: 0px solid black;
    }

#pv {
    align-content: end;
}

.AALogo {
    padding-top: 32px;
}

.AAShadow {
    padding-top: 32px;
    padding-bottom: 32px;
}

input[type="radio"] {
    accent-color: #1476B6;
    margin-right: 10px;
}

input[type="checkbox"] {
    accent-color: #1476B6;
    margin-right: 10px;
}